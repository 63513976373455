import { BiData } from "react-icons/bi";
import { RiReactjsLine } from "react-icons/ri";
import { FaServer } from "react-icons/fa";
import { BsFillEyeFill } from "react-icons/bs";

const data = [
  {
    id: 1,
    icon: <RiReactjsLine />,
    title: "Desarrollo de Frontend",
    desc: "Especializado en el desarrollo de aplicaciones web utilizando las tecnologías más modernas y avanzadas.",
  },
  {
    id: 2,
    icon: <FaServer />,
    title: "Desarrollo de Backend",
    desc: "Excelentes habilidades para crear aplicaciones del lado del servidor eficientes y de alto rendimiento.",
  },
  {
    id: 3,
    icon: <BsFillEyeFill />,
    title: "Observabilidad Completa",
    desc: "Experiencia práctica con plataformas de observabilidad y monitoreo de infraestructura.",
    
  },
  {
    id: 4,
    icon: <BiData />,
    title: "Gestión de Datos",
    desc:  "Experiencia en proyectos tecnológicos que integran la ingesta, el procesamiento y el análisis de datos.",
  },
];

export default data;
